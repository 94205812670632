<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.friendEntId" filterable url="api/distributor" placeholder="经销商" value-field="enterpriseId" clearable @change="toQuery" class="filter-item" style="width: 210px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="收款日期起始" end-placeholder="收款日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-plus" @click="add">新建费用单</el-button>
    </div>
    <eForm ref="form" />

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="code" label="费用单号" width="150" />
      <el-table-column prop="friendEntName" label="经销商" width="180" />
      <el-table-column label="类型" width="70">
        <template slot-scope="scope">{{scope.row.payMoney < 0 ? '退款' : '收款'}}</template>
      </el-table-column>
      <el-table-column label="金额" align="right" width="120">
        <template slot-scope="scope">
          <b :class="scope.row.payMoney < 0 ? 'fc-e' : ''">{{$price(scope.row.payMoney)}}</b>
        </template>
      </el-table-column>
      <el-table-column prop="payTime" label="收款日期" width="120" :formatter="r => {return new Date(r.payTime).format('yyyy/MM/dd');}" />
      <el-table-column label="支付方式" width="120" :formatter="r => {return paymentMethods[r.payType];}" />
      <el-table-column prop="info" label="说明" show-overflow-tooltip min-width="240" />
      <el-table-column label="状态" width="80" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="50px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="detail(scope.row)">查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      paymentMethods: ["在线支付", "线下支付"],
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      status: [
        {
          type: "info",
          label: "待审批"
        },
        {
          type: "success",
          label: "已通过"
        },
        {
          type: "danger",
          label: "未通过"
        }
      ],
      query:{
        friendEntId:null,
        dateRange:null,
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/payForm";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        { relationFormType: 1, sort: "code,desc" },
        query
      );
      return true;
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm(null, 1);
    },
    detail(data) {
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    }
  }
};
</script>